<template lang="pug">
  div
    slot(name="activator")
      v-btn(small color='primary'
        :disabled="disabled"
        @click="show"
        outlined
      ) Send to Landing
    confirmation-dialog(
      v-model="showing"
      title="Send to Landing"
      okText="Ok"
      failText="Cancel"
      descr="Are you sure you want to sent candidate to landing?"
      @okBtnClicked="send"
      @failBtnClicked="hide"
      @click:outside="hide"
    )
</template>

<script>
  import errorsMixin from '@/mixins/errors.mixin'

  export default {
    inject: ['svc'],
    mixins: [ errorsMixin ],
    props: {
      ID: {
        type: Number
      },
      disabled: Boolean,
    },
    data: () => ({
      showing: false,
      loading: false,
    }),
    methods: {
      show() {this.showing = true},
      hide() {this.showing = false},
      async send() {
        if (!this.loading) {
          this.loading = true;
          try {
            await this.svc().sendToLanding(this.ID)
            this.$notify({text: 'To landing request sended', type: 'success'})
            this.hide()
          } catch(err) {
            this.processError(err)
          } finally {
            this.loading = false;
          }
        }
      },
    },
    components: {
      confirmationDialog: () => import('@/components/global/ConfirmationDialog.vue')
    }

  }
</script>